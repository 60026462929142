// configLoader.js
import {platformsettingsSysconfigGetconfig} from '@/api/api'

// 加载系统配置
async function loadConfig() {
  const data = await platformsettingsSysconfigGetconfig()
  // console.log(JSON.stringify(data, null, 2))
  let logo = null;

  // 遍历customdata数组，查找name为"系统名称"和"logo"的项
  for (let item of data.data.data) {
    if (item.name === "系统logo") {
        logo = {name: "系统logo", value: item.value};
    }
  }
  // 如果找不到系统名称或logo，设置为默认值
  if (!logo) {
      logo = {name: "系统logo", value: "./static/logo.png"};
  }
  return {
      logo,
      // 其他参数...
  };
}

export default loadConfig;