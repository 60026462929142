<template>
    <div>
        <ly-crud ref="lycrud"  v-bind="crudConfig" >
            <!-- <template v-slot:customHandle="slotProps"> -->
                <!-- <span class="table-operate-btn" @click="handleClick(slotProps.row,'disable')" v-show="hasPermission(this.$route.name,'Delete')">删除</span> -->
            <!-- </template> -->
        </ly-crud>
    </div>
</template>

<script lang="jsx">
    import {RechargeGoods,RechargeGoodsAdd,RechargeGoodsDelete,RechargeGoodsEdit} from '@/api/api'
    import LyCrud from "@/components/lycrud";
    import templateData from "@/components/dict/crudTemplateData"
    import { h,resolveComponent } from 'vue';
    export default {
        name: "RechargeGoods",
        components: {LyCrud},
        data(){
            return{
                //crud配置
                crudConfig:{
                    //crud请求方法配置
                    crudRequest:{
                        add:RechargeGoodsAdd,
                        del:RechargeGoodsDelete,
                        edit:RechargeGoodsEdit,
                        search:RechargeGoods,
                    },
                    //显示分页
                    showPagination:true,
                    //分页配置
                    // pageparams: {
                        // limit: 2,//每页显示的条数(默认每页显示10条)//非必填
                        //pageSizes:[10,20,30,40,50,200],//非必填
                        // layout:'total, sizes, prev, pager, next, jumper',//非必填
                    // },
                    //crud按钮配置
                    permission:{//增删改查按钮权限控制（是否显示）
                            add: this.hasPermission(this.$route.name, 'Create'),//bool型 this.$options.name获取当前组件的name本例为userManageCrud
                            del: this.hasPermission(this.$route.name, 'Delete'),
                            edit: this.hasPermission(this.$route.name, 'Update'),
                            search: this.hasPermission(this.$route.name, 'Search'),
                            detail: this.hasPermission(this.$route.name, 'Retrieve'),
                    },
                    //crud弹窗属性
                    formOptions: {
                        width:'45%',//dialog弹窗宽度：类型：百分比或字符串
                        gutter: 20, // Layout布局栅格间隔
                    },
                    //crud表格属性
                    tableOptions:{
                        border:true,
                        showHeader:true,
                    },
                    showSelectable:true,//表格显示复选项框
                    //table表头列
                    tableColumns:[
                    {
                        label: 'ID', prop: 'id', type: 'input', minWidth: '100', sortable: false, hidden: true,
                        form: {
                            //表单属性
                            span: 24,
                            hidden: true,//编辑时隐藏，添加时去除
                        }
                    },
                    {
                        label: '排序', prop: 'sort', type: 'age', minWidth: '100', sortable: false, hidden: true,
                        form: {
                            //表单属性
                            span: 24,
                            // hidden: true,//编辑时隐藏，添加时去除
                        }
                    },
                    {label:'充值金额',prop:'recharge_goods',type:'input',minWidth:'100',sortable: false,hidden:false,
                        form:{
                        //表单属性
                        span:24,
                        rules: [{ required: true, message: '充值金额必填项' }],
                        placeholder: '请输入充值金额',
                        }
                    },
                    {label:'销售金额',type:'input',prop:'price',minWidth:'100',sortable: false,hidden:false,
                        form:{
                        //表单属性
                        span:24,
                        rules: [{ required: true, message: '销售金额必填项' }],
                        placeholder: '请输入销售金额',
                        // width:80,头像大小默认80px
                        }

                    },
                    {label:'库存',prop:'stock',type:'input',minWidth:'100',sortable: false,hidden:false,
                        form:{
                        //表单属性
                        span:24,
                        rules: [{ required: true, message: '库存必填项' }],
                        placeholder: '请输入库存',
                        }
                    },
                    {label:'销量',prop:'sales',type:'input',minWidth:'100',sortable: false,hidden:false,
                        form:{
                        //表单属性
                        span:24,
                        hidden: true,//编辑时隐藏，添加时去除
                        }
                    },
                    {label:'支付方式',prop:'pay_type',type:'radio',minWidth:'100',sortable: false,hidden:false,
                        render: (row) => {
                        let elTag = resolveComponent('el-tag')
                        switch(row.pay_type){
                            case 1:
                            return h(elTag, {
                                type:'success'
                            },()=>'微信支付');
                            case 2:
                            return h(elTag, {
                                type:'warning'
                            },()=>'支付宝支付');
                            default:
                            return h(elTag, {},()=>'未知支付方式');
                        }
                        },
                        form:{
                        //表单属性
                        span:24,
                        options:templateData.PAY_TYPE,//radio选项属性值
                        defaultValue:1,//新增时的默认值
                        rules: [{ required: true, message: '设置支付方式必填项' }],
                        // valueChange(e){//值变化回调事件
                        //     console.log(e,'---变化的值')
                        // },
                        }
                    },
                    {label:'充值类型',prop:'recharge_type',type:'radio',minWidth:'100',sortable: false,hidden:false,
                        render: (row) => {
                        let elTag = resolveComponent('el-tag')
                        switch(row.recharge_type){
                            case 1:
                            return h(elTag, {
                                type:'success'
                            },()=>'自由充值');
                            case 2:
                            return h(elTag, {
                                type:'warning'
                            },()=>'固定充值');
                        }
                        },
                        form:{
                        //表单属性
                        span:24,
                        options:templateData.RECHARGE_TYPE,//radio选项属性值
                        defaultValue:2,//新增时的默认值
                        }
                    },
                    {label:'是否上架',prop:'is_show',type:'radio',minWidth:'100',sortable: false,hidden:false,
                        render: (row) => {
                        let elTag = resolveComponent('el-tag')
                        if(row.is_show){
                            return h(elTag, {
                            type:'success'
                            },()=>'上架');
                        }else {
                            return h(elTag, {
                            type:'warning'
                            },()=>'下架');
                        }
                        },
                        form:{
                        //表单属性
                        span:24,
                        options:templateData.WHETHER_BOOL,//radio选项属性值
                        defaultValue:true,//新增时的默认值
                        rules: [{ required: true, message: '设置是否上架必填项' }],
                        }
                    },
                    {label:'创建时间',prop:'create_datetime',minWidth:'150',sortable: false,hidden:false},
                    ],
                },

                }
            },
    }
</script>

<style scoped>

</style>

