/**
 * lycrud需要经常用到的数据对象/数组
 */
export default {
    // 启用 true/ 禁用 false
    STATUS_BOOL:[{ label: '启用', value: true }, { label: '禁用', value: false }],
    // 启用 1/ 禁用 0
    STATUS_NUMBER:[{ label: '启用', value: 1 }, { label: '禁用', value: 0 }],
    // 是 1/ 否 0
    WHETHER_NUMBER:[{ label: '是', value: 1 }, { label: '否', value: 0 }],
    // 是 true/ 否 false
    WHETHER_BOOL:[{ label: '是', value: true }, { label: '否', value: false }],
    // 支付方式
    PAY_TYPE:[{ label: '微信支付', value: 1 }, { label: '支付宝支付', value: 2 }],
    // 支付状态
    PAY_STATUS:[{ label: '未支付', value: 0 }, { label: '已支付', value: 1 }],
    // 充值方式
    RECHARGE_TYPE:[{ label: '自由充值', value: 1 }, { label: '固定充值', value: 2 }],
}