<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="用户昵称：">
                    <el-input v-model.trim="formInline.nickname" maxlength="60"  clearable placeholder="用户昵称" @change="search" style="width:160px"></el-input>
                </el-form-item>
                <el-form-item label="手机号：">
                    <el-input v-model.trim="formInline.mobile" maxlength="60"  clearable placeholder="手机号" @change="search" style="width:160px"></el-input>
                </el-form-item>
                <el-form-item label="创建时间：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="addModule" type="primary" icon="Plus" v-show="hasPermission(this.$route.name,'Create')">新增</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="exportDataBackend" type="primary">导出</el-button></el-form-item>
            </el-form>
        </div>

        <div class="table">
            <ly-table tableName="userManageTable" :height="tableHeight" :pageSize="10" :apiObj="this.$Api.UsersUsers" :params="formInline" ref="tableref" :column="column" showSequence>
                <template #avatar="scope">
                    <el-image  :src="scope.row.avatar ? scope.row.avatar : defaultImg" :preview-src-list="[scope.row.avatar]" style="width: 30px;height: 30px" preview-teleported></el-image>
                </template>
                <!-- <template #is_active="scope">
                    <el-tag v-if="scope.row.is_active">正常</el-tag>
                    <el-tag v-else type="danger">禁用</el-tag>
                </template> -->
                <template #is_active="scope">
                    <el-switch v-model="scope.row.is_active" active-color="#13ce66" inactive-color="#ff4949" @change="changeStatus(scope.row)"></el-switch>
                </template>
                <el-table-column label="操作" fixed="right" width="180">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon ><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')" v-show="hasPermission(this.$route.name,'Update')">编辑</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')" v-show="hasPermission(this.$route.name,'Retrieve')">详情</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="hasPermission(this.$route.name,'Delete')">删除</span>
                        <!-- <span class="table-operate-btn" @click="handleEdit(scope.row,'disable')" v-show="hasPermission(this.$route.name,'Update')">
                            <span v-if="scope.row.is_active">禁用</span>
                            <span v-else>启用</span>
                        </span> -->
                    </template>
                </el-table-column>
            </ly-table>
        </div>
        <addUser ref="addUserFlag" @refreshData="getData" v-if="isDialogVisible" @closed="isDialogVisible = false"></addUser>
        <userDetail ref="userDetailFlag" v-if="isDetailDialogVisible" @closed="isDetailDialogVisible = false"></userDetail>
    </div>
</template>
<script>
    import addUser from "./components/addUser";
    import {dateFormats,getTableHeight} from "@/utils/util";
    import {UsersUsers,UsersUsersDelete,UsersUsersdisableEdit,UsersUsersExportexecl} from '@/api/api'
    import userDetail from "./components/userDetail";
    export default {
        components:{
            userDetail,
            addUser
        },
        name:'userManage',
        data() {
            return {
                isFull:false,
                tableHeight:500,
                loadingPage:false,
                isDetailDialogVisible: false,
                isDialogVisible: false,
                formInline:{},
                defaultImg:require('../../assets/img/avatar.jpg'),
                statusList:[
                    {id:1,name:'正常'},
                    {id:0,name:'禁用'}
                ],
                identityList:[
                    {id:0,name:'普通用户'},
                    {id:1,name:'会员'},
                ],
                timers:[],
                tableData:[],
                column: [
                    {
                        label: "用户头像",
                        prop: "avatar",
                        width:"100"
                    },
                    {
                        label: "用户名",
                        prop: "username",
                        minWidth:"110"
                    },
                    {
                        label: "用户昵称",
                        prop: "nickname",
                        minWidth:"110"
                    },
                    {
                        label: "手机号",
                        prop: "mobile",
                        minWidth:"100",
                    },
                    {
                        label: "状态",
                        prop: "is_active",
                        width:"100"
                    },
                    {
                        label: "创建时间",
                        prop: "create_datetime",
                        minWidth:"180"
                    }
                ],
            }
        },

        methods:{
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            handleAddClick() {
                this.isDialogVisible = true
                this.$nextTick(() => {
                    this.$refs.addUserFlag.handleOpen(null, "新增")
                })
            },
            handleEdit(row,flag) {
                if(flag=='edit') {
                    this.isDialogVisible = true
                    this.$nextTick(() => {
                        this.$refs.addUserFlag.handleOpen(row, "编辑")
                    })
                }
                else if(flag=='detail') {
                    this.isDetailDialogVisible = true
                    this.$nextTick(() => {
                        this.$refs.userDetailFlag.handleOpen(row,'详情')
                    })
                }
                else if(flag=='disable'){
                    let vm = this
                    UsersUsersdisableEdit({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.search()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                }
                else if(flag=='delete') {
                    let vm = this
                    vm.$confirm('您确定要删除选中的数据吗？',"警告",{
                        closeOnClickModal:false,
                        type:"warning"
                    }).then(res=>{
                        UsersUsersDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.search()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }
                else if(flag=="reset"){
                    this.formInline = {}
                    this.timers = []
                    this.search()
                }
            },
            changeStatus(row) {
                let vm = this
                let flat = row.is_active
                row.is_active = !row.is_active
                vm.$confirm('确定修改状态吗？', '提醒', {
                    closeOnClickModal: false,
                    type:"warning"
                }).then(ret => {
                    UsersUsersdisableEdit({ id: row.id }).then(res => {
                        if (res.code == 2000) {
                            flat ? row.is_active = true : row.is_active = false
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {

                })
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFileURL(url) {
                var iframe =document.createElement("iframe")
                iframe.style.display ="none";
                iframe.src = url;
                document.body.appendChild(iframe);
            },
            exportDataBackend() {
                var params = {
                    page: 1,
                    limit: 9999,
                }
                UsersUsersExportexecl(params).then(res => {
                     if(res.code ==2000) {
                         this.downloadFileURL(res.data.data)
                     }
                 })
            },
            search() {
                this.$refs.tableref.reload(this.formInline)
            },
            //获取列表
            async getData() {
                this.$refs.tableref.getData()
            },
            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            // 计算搜索栏的高度
            listenResize() {
				this.$nextTick(() => {
				    this.getTheTableHeight()
				})
			},
            getTheTableHeight(){
                let tabSelectHeight = this.$refs.tableSelect?this.$refs.tableSelect.offsetHeight:0
                tabSelectHeight = this.isFull?tabSelectHeight - 110:tabSelectHeight
                this.tableHeight =  getTableHeight(tabSelectHeight,false) - 125
            }

        },
        mounted() {
            // 监听页面宽度变化搜索框的高度
            window.addEventListener('resize', this.listenResize);
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        unmounted() {
             // 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
        },
    }
</script>