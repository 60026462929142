<template>
    <div class="lyareadialog">
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" :fullscreen="fullscreen" :loading="dialogLoading"
            :width="width" :before-close="handleClose" @closed="closed">
            <el-alert title="其他已配置过的配送区域不会在此展示！！！" type="warning" />
            <el-row :gutter="24" type="flex">
                <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
                    <div class="handleOpButton">
                        <el-checkbox v-model="isAllSelect" @change="handelAllCheckbox">全选</el-checkbox>
                        <div class="sempty" @click="hanelEmpty">清空</div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="24" :loading="dialogLoading" justify="start">
                <el-col :xl="6" :lg="6" :md="6" :sm="8" :xs="6" v-for="(item, index) in   areaList  " :key="index"
                    style="height: 50px;">
                    <el-popover placement="top-start" width="260" trigger="hover" :show-after="600">
                        <div v-if="!!item.childlist && item.childlist.length > 0">
                            <el-tree-v2 :data="item.childlist" :props="customProps" show-checkbox :check-strictly="false"
                                :ref="(el) => setTreeRef(el, 'treeRef' + item.id)" node-key="id" :height="360"
                                @check-change="handleCheckChange" :default-checked-keys="formData">
                                <template #default="{ node, data }">
                                    {{ node.label }}<span class="red" v-if="data.level == 2">({{ (data.count || 0) + '/' +
                                        data.childlist.length
                                    }})</span>
                                </template>
                            </el-tree-v2>
                        </div>
                        <template #reference>
                            <el-checkbox v-model="item.checked" :label="item.name" @change="handelProiceCheckbox(item)"
                                :indeterminate="item.is_half">
                                {{ item.name }}
                                <el-icon v-if="!!item.childlist && item.childlist.length > 0">
                                    <CaretBottom />
                                </el-icon>
                                <span class="red">({{ (item.count || 0) + '/' + item.childlist.length }})</span>
                            </el-checkbox>
                        </template>
                    </el-popover>
                </el-col>
            </el-row>
            <template #footer>
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" @click="submitData">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script setup>
import LyDialog from "@/components/dialog/dialog";
import { getAllAreasList } from "@/api/api";
import { ref, onMounted, watch, computed } from 'vue'
import { deepClone } from "@/utils/util"
import { ElMessage } from 'element-plus'

const props = defineProps({
    size: { type: String, default: "default" },
    fullscreen: { type: Boolean, default: false },
    width: { type: String, default: "880px" },
})

const emits = defineEmits(['refreshData', 'closed'])

let dialogVisible = ref(false)
let loadingTitle = ref("选择区域")
let dialogLoading = ref(false)
let loadingSave = ref(false)

let isAllSelect = ref(false)


let formData = ref([])
let selectNames = ref([])
let selectedList = ref([])//需要隐藏不显示的区域id

function closed() {
    emits('closed')
}

const customProps = {
    label: 'name',
    value: 'id',
    children: 'childlist',
}

let treeRefObj = {}
function setTreeRef(el, key) {
    if (el) {
        treeRefObj[key] = el
    }
}

function changeTreeChecked(val, newData = []) {
    if (val.length !== 0) {
        val.forEach(item => {
            item.count = 0
            if (newData.indexOf(item.id) > -1) {
                item.checked = true
            } else {
                item.checked = false
            }
            if (!!item.childlist && item.childlist.length > 0) {
                changeTreeChecked(item.childlist, newData);
            }
        });
    }
    return val
}

function changeTreeCount(val) {
    if (val.length !== 0) {
        val.forEach(item => {
            if (item.level == 1) {
                if (item.checked) {
                    item.count = item.childlist.length
                    item.is_half = false
                } else {
                    item.count = (item.childlist.filter(items => items.checked)).length
                    if (item.count == 0) {
                        item.is_half = false
                    } else if (item.count < item.childlist.length) {
                        item.is_half = true
                    }
                }
            } else if (item.level == 2) {
                if (item.checked) {
                    item.count = item.childlist.length
                } else {
                    item.count = (item.childlist.filter(items => items.checked)).length
                }
            }
            if (!!item.childlist && item.childlist.length > 0) {
                changeTreeCount(item.childlist);
            }
        });
    }
    return val
}

//数组递归删除数据
function changeTreeDeleteSeleted(arr) {
    if (arr.length !== 0 && selectedList.value.length > 0) {
        for (let i = 0; i < arr.length; i++) {
            let item = arr[i];
            if (selectedList.value.indexOf(item.id) != -1) {
                arr.splice(i, 1); // 删除符合条件的元素
                i--; // 删除元素后需要将索引回退，以便下一次循环正确处理
            }
            if (!!item.childlist && item.childlist.length > 0) {
                changeTreeDeleteSeleted(item.childlist);
            }
        }
    }
}

function EmptyTree(val) {
    if (val.length != 0) {
        val.forEach(item => {
            item.checked = false
            item.count = 0
            if (!!item.childlist && item.childlist.length >= 1) {
                EmptyTree(item.childlist);
            }
        });
    }
    return
}

let areaList = ref([])
function getAllAreaslistData() {
    dialogLoading.value = true
    getAllAreasList({ subdistrict: 2 }).then(res => {
        dialogLoading.value = false
        if (res.code === 2000) {
            let odata = res.data.data
            changeTreeDeleteSeleted(odata)
            odata = changeTreeChecked(odata, formData.value)
            odata = changeTreeCount(odata)
            areaList.value = odata
        }
    })
}

function handleOpen(item, names, hideAreaList, flag) {
    dialogVisible.value = true
    loadingTitle.value = flag || "选择区域"
    formData.value = item || []
    selectNames.value = names.split("、") || []
    selectedList.value = hideAreaList
    getAllAreaslistData()

}

function handleClose() {
    dialogVisible.value = false
    dialogLoading.value = false
    loadingSave.value = false
    formData.value = []
    selectNames.value = []
    selectedList.value = []
}

function handleCheckChange(data, checked) {
    data.checked = checked
    if (data.level == 2) {
        setLevel2TreeChecked(data.id, data.pid, checked)
    } else {
        for (var i = 0; i < areaList.value.length; i++) {
            for (var s = 0; s < areaList.value[i].childlist.length; s++) {
                if (areaList.value[i].childlist[s].id == data.pid) {
                    areaList.value[i].childlist[s].count = (areaList.value[i].childlist[s].childlist.filter(items => items.checked)).length
                    if (!checked) {
                        areaList.value[i].childlist[s].checked = false
                        areaList.value[i].checked = false
                        areaList.value[i].is_half = true
                        if (areaList.value[i].childlist[s].count == 0) {
                            areaList.value[i].count = areaList.value[i].count - 1
                        }
                    } else {
                        if (areaList.value[i].childlist[s].count == areaList.value[i].childlist[s].childlist.length) {
                            areaList.value[i].childlist[s].checked = true
                            areaList.value[i].count = areaList.value[i].count + 1
                            if (areaList.value[i].count >= areaList.value[i].childlist.length) {
                                areaList.value[i].is_half = false
                                areaList.value[i].checked = true
                                areaList.value[i].count = areaList.value[i].childlist.length
                            }
                        }
                    }
                    break
                }
            }
        }
    }
    // formData.value = Array.from(new Set(formData.value));
    // selectNames.value = Array.from(new Set(selectNames.value));
}

function getDataIds(val) {
    let objs = []
    if (!!val && val.length !== 0) {
        val.forEach(item => {
            objs.push(item.id)
            if (!!item.childlist && item.childlist.length > 0) {
                objs.concat(getDataIds(item.childlist))
            }
        });
    }
    return objs
}


function setLevel1TreeChecked(id, checked = false) {
    let key = 'treeRef' + id
    for (var i = 0; i < areaList.value.length; i++) {
        if (areaList.value[i].id == id) {
            areaList.value[i].is_half = false
            for (var s = 0; s < areaList.value[i].childlist.length; s++) {
                let datas = areaList.value[i].childlist[s]
                treeRefObj[key].setChecked(datas.id, checked)
                areaList.value[i].childlist[s].checked = checked
                let count = 0
                if (checked) {
                    count = datas.childlist.length
                }
                areaList.value[i].childlist[s].count = count
                for (var x = 0; x < datas.childlist.length; x++) {
                    treeRefObj[key].setChecked(areaList.value[i].childlist[s].childlist[x].id, checked)
                    areaList.value[i].childlist[s].childlist[x].checked = checked
                }
            }
            break
        }
    }
}

function setLevel2TreeChecked(id, pid, checked = false) {
    let key = 'treeRef' + pid
    for (var i = 0; i < areaList.value.length; i++) {
        for (var s = 0; s < areaList.value[i].childlist.length; s++) {
            let datas = areaList.value[i].childlist[s]
            if (datas.id == id) {
                areaList.value[i].childlist[s].checked = checked
                let count = 0
                if (checked) {
                    count = datas.childlist.length
                }
                areaList.value[i].childlist[s].count = count
                for (var x = 0; x < datas.childlist.length; x++) {
                    treeRefObj[key].setChecked(areaList.value[i].childlist[s].childlist[x].id, checked)
                    areaList.value[i].childlist[s].childlist[x].checked = checked
                }
                areaList.value[i].count = (areaList.value[i].childlist.filter(items => items.checked)).length
                if (areaList.value[i].count == areaList.value[i].childlist.length) {
                    areaList.value[i].is_half = false
                    areaList.value[i].checked = true
                } else if (areaList.value[i].count > 0) {
                    areaList.value[i].is_half = true
                    areaList.value[i].checked = false
                } else {
                    areaList.value[i].is_half = false
                    areaList.value[i].checked = false
                }
                break
            }

        }
    }
}

function handelProiceCheckbox(e) {
    if (e.checked) {
        e.count = e.childlist.length
        setLevel1TreeChecked(e.id, true)
    } else {
        e.count = 0
        setLevel1TreeChecked(e.id, false)
    }
}

function submitData() {
    let newFormData = []
    let newSelectNames = []
    areaList.value.forEach(item => {
        let levle2CheckCount = (item.childlist.filter(items => items.checked)).length
        let level2Count = item.childlist.length
        //如果选择了省则name只取省名称
        if (levle2CheckCount == level2Count && item.checked) {
            newSelectNames.push(item.name)
            newFormData.push(item.id)
            let newids1 = getDataIds(item.childlist)
            newFormData.push(...newids1)
        } else {
            if (!!item.childlist && item.childlist.length > 0) {
                let newnames = item.name + "（"
                let proVNames = []
                let proChildNames = []
                item.childlist.forEach(item2 => {
                    let level3CheckItems = item2.childlist.filter(items => items.checked)
                    let levle3CheckCount = level3CheckItems.length
                    let level3Count = item2.childlist.length
                    if (levle3CheckCount == level3Count && item2.checked) {
                        proVNames.push(item2.name)
                        newFormData.push(item2.id)
                        let newids2 = getDataIds(item2.childlist)
                        newFormData.push(...newids2)
                    } else {
                        let level3Names = item2.name + "（"
                        let level3ChildNames = []
                        level3CheckItems.forEach((item3, index3) => {
                            level3ChildNames.push(item3.name)
                            newFormData.push(item3.id)
                        })
                        if (level3ChildNames.length > 0) {
                            level3Names = level3Names + level3ChildNames.join('、') + "）"
                            proChildNames.push(level3Names)
                        }
                    }

                })
                if (proVNames.length > 0 || proChildNames.length > 0) {
                    let newPdAddr = [...proVNames, ...proChildNames]
                    newnames = newnames + newPdAddr.join('、') + "）"
                    newSelectNames.push(newnames)
                }
            }
        }
    });
    if (newFormData.length < 1) {
        ElMessage.warning("请选择区域")
        return
    }
    emits('refreshData', newFormData, newSelectNames.join('、'))
    closed()
}

function handelAllCheckbox(checked) {
    if (checked) {
        formData.value = []
        selectNames.value = []
        areaList.value.forEach(item => {
            item.checked = true
            item.count = item.childlist.length
            formData.value.push(item.id)
            selectNames.value.push(item.name)
        })
    } else {
        hanelEmpty()
    }

}

function hanelEmpty() {
    isAllSelect.value = false
    formData.value = []
    selectNames.value = []
    EmptyTree(areaList.value)
    for (var i in treeRefObj) {
        let keys = treeRefObj[i].getCheckedKeys()
        if (keys.length > 0) {
            keys.forEach(item => {
                treeRefObj[i].setChecked(item, false)
            })
        }
    }

}

defineExpose({
    handleOpen
})
</script>

<style scoped>
.red {
    color: red;
}


.handleOpButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sempty {
    cursor: pointer;
    margin-left: 10px
}
</style>