<!--
/**
 * 代码编辑器
 * version: 1.3
 * author: lybbn
 * program django-vue-lyadmin
 * email: 1042594286@qq.com
 * website: http://doc.lybbn.cn
 * date: 2023.07.29
 * newDate: 2023-12-23
 * remark: 如果要分发django-vue-lyadmin源码或其中组件等，需在本文件顶部保留此文件头信息！！！
 */
-->
<template>
	<codemirror
		ref="lyCodemirror"
		v-model="code"
		:placeholder="placeholder"
		:style="{ height: _height,fontSize:'16px' }"
		:autofocus="false"
		:indent-with-tab="true"
		:tab-size="2"
		:extensions="extensions"
		@ready="handleReady"
	/>
</template>

<script setup>

	import {ref,reactive, onMounted,computed,watch, shallowRef,nextTick} from 'vue'
	import { Codemirror } from 'vue-codemirror'
	import { EditorState,Compartment } from "@codemirror/state";
	import { basicSetup,minimalSetup } from "codemirror";
	import { defaultKeymap,standardKeymap, insertTab } from "@codemirror/commands";
	import { EditorView,keymap} from "@codemirror/view";
	import { autocompletion } from "@codemirror/autocomplete";
	// import { historyKeymap } from "@codemirror/history";
	import { oneDark } from "@codemirror/theme-one-dark";
	import { javascript } from "@codemirror/lang-javascript";
	import { json } from "@codemirror/lang-json";
	import { css } from "@codemirror/lang-css";
	import { python } from "@codemirror/lang-python";
	import { vue } from  "@codemirror/lang-vue"

	const emit = defineEmits(["update:modelValue"])
	const props = defineProps({
        modelValue: {
			type: String,
			default: ""
		},
		mode: {
			type: String,
			default: "javascript"
		},
		height: {
			type: [String,Number],
			default: 300,
		},
		placeholder: {
			type: String,
			default:""
		},
		theme: {
			type: String,
			default: "dark"
		},
		readOnly: {
			type: Boolean,
			default: false
		},
		bottom:{
        	type: Boolean,
			default: false
		}
    })
	let _height =  computed(() => {
        return Number(props.height)?Number(props.height)+'px':props.height
    })
	//code和modelValue双向绑定
	const code = computed({
		get() {
			return props.modelValue
		},
		set(value) {
			emit('update:modelValue', value)
		}
	})
	//移动到指定行
	function moveToLine(view,line) {
		if(view == null || view.state == null){
			return false
		}
		if (!/^\d+$/.test(line) || +line <= 0 || +line > view.state.doc.lines){
			return false
		}
		let pos = view.state.doc.line(+line).from
		view.dispatch({selection: {anchor: pos}, userEvent: "select",scrollIntoView:true})
		return true
	}
    let view = ref(null)
	const handleReady = (payload) => {
        view.value = payload.view
		// const state = view.value.state
        // const ranges = state.selection.ranges
        // const selected = ranges.reduce((r, range) => r + range.to - range.from, 0)
        // const cursor = ranges[0].anchor
        // const length = state.doc.length
        // const lines = state.doc.lines
	}
	//获取总行数
	const getDocLines = ()=>{
		if(!!view.value){
			return view.value.state.doc.lines
		}
		return 0
	}
	let allLines = ref(getDocLines())

	let languageConf = new Compartment, tabSize = new Compartment
	let lang = computed(() => {
        if(props.mode == "javascript"){
        	return javascript()
		}else if(props.mode == "json"){
			return json()
		}else if(props.mode == "css"){
			return css()
		}else if(props.mode == "python"){
        	return python()
		}else if(props.mode == "vue"){
        	return vue()
		}
        else{
        	return javascript()
		}
    })
	//自定义代码提示
	function myCompletions(context) {
		let word = context.matchBefore(/\w*/)
		if (word.from == word.to && !context.explicit) return null;
		return {
			from: word.from,
			options: [
				{ label: "getWidgetRef", type: "variable" },
				{ label: "getValue", type: "variable" },
				{ label: "setValue", type: "variable" },
				{ label: "setHidden", type: "variable" },
				{ label: "setFormData", type: "variable" },
				{ label: "getFormData", type: "variable" },
				{ label: "getFormRef", type: "variable" },
			],
		};
	}

	let extensions = [
		basicSetup,
		keymap.of([...standardKeymap, {key: "Tab", run: insertTab}]),
		languageConf.of(lang.value),
		oneDark,
		autocompletion({ override: [myCompletions] }),
		EditorState.readOnly.of(props.readOnly),
		// EditorView.editable.of(!props.readOnly),
		// placeholder(props.placeholder),
	]
	onMounted(()=>{
		if(props.bottom && !!view.value){
			nextTick(()=>{
				moveToLine(view.value,getDocLines())
				// view.value.focus()
			})
		}
	})

	function scrollToBottom(){
		if(!!view.value){
			moveToLine(view.value,getDocLines())
			// view.value.focus()
		}
	}

	watch(()=>code.value,newValue=>{
		if(props.bottom && !!view.value){
			nextTick(()=>{
				moveToLine(view.value,getDocLines())
				// view.value.focus()
			})
		}
	})

	defineExpose({
		scrollToBottom,
	})
</script>

<style>
	.cm-content {
		/* font-family: "Arial", sans-serif; */
		font-family: Verdana ,sans-serif;
	}
</style>